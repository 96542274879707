import { useEffect, useRef } from "react";
import { Box, Grid } from "@mui/material";
import { Image } from "antd";

const InfiniteCarousel = ({ images }) => {
    const scrollRef = useRef(null);

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        if (!scrollContainer) return;

        let scrollSpeed = 1; // Adjust speed
        let animationFrame;

        const scroll = () => {
            if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
                scrollContainer.scrollLeft = 0; // Reset scroll
            } else {
                scrollContainer.scrollLeft += scrollSpeed;
            }
            animationFrame = requestAnimationFrame(scroll);
        };

        animationFrame = requestAnimationFrame(scroll);

        return () => cancelAnimationFrame(animationFrame);
    }, []);

    return (
        <Grid container my={10} ref={scrollRef} sx={{
            overflowX: "hidden",
            maxWidth: "100%",
            paddingBottom: 2,
            display: "flex",
            flexWrap: "nowrap",
            whiteSpace: "nowrap"
        }}>
            {[...images, ...images].map((image, idx) => ( // Duplicate images
                <Grid item xs={2} key={idx} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ width: "10vw", height: "10vw", marginRight: 2 }}>
                        <Image preview={false} src={image} />
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default InfiniteCarousel;
